import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider"
import { ScrollTrigger } from "gsap/all";

export default class Slider {

  constructor(selector) {
    this.sliders = document.querySelectorAll(selector);
    if (!this.sliders) {
      return;
    }

    for (let i = 0; i < this.sliders.length; ++i) {
      const slider = this.sliders[i];
      const sliderItems = slider.querySelectorAll('.slider--item');

      if (sliderItems.length > 1) {
        // init slider
        tns({
          container: slider,
          mode: 'carousel',
          speed: 600,
          autoplay: true,
          autoplayTimeout: 6000,
          autoplayButtonOutput: false,
          autoHeight: false,
          navPosition: 'bottom',
          controlsPosition: 'bottom',
        });

        ScrollTrigger.refresh();
      }

    }

  }

}
