import animateScrollTo from 'animated-scroll-to';

export default class Jumpmenu {

  constructor(selector) {
    this.header = document.querySelector('.header');
    this.links = document.querySelectorAll('.nav--link');
    this.targets = document.querySelectorAll('.navigation--target');
    this.headerLinks = document.querySelectorAll('.navigation--header a');

    this.prepare();
  }


  prepare() {
    for (let i = 0; i < this.links.length; ++i) {
      const _target = document.querySelector(this.links[i].hash + '-target');

      this.links[i].addEventListener('click', (event) => {
        event.preventDefault();
        if (_target) {
          this.scrollTo(_target);
        }
      });
    }

    if (window.location.hash) {
      let _target = document.querySelector(window.location.hash + '-target');
      if (_target) {
        setTimeout(() => {
          this.scrollTo(_target);
        }, 200);
      }
    }

  }


  // aimToTarget(link, target) {
  //   if (target) {
  //     this.scrollTo(target);
  //   }
  // }


  activateLink (link) {
    for (let i = 0; i < this.links.length; ++i) {
      this.links[i].classList.remove('is-active');
    }
    if (link) {
      link.classList.add('is-active');
    }
  }


  scrollTo(target) {
    let _offset = this.header.clientHeight;
    const _options = {
      speed: 500,
      // minimum duration of the scroll
      minDuration: 500,
      // maximum duration of the scroll
      maxDuration: 1500,
      verticalOffset: -1 * (_offset-1)
    };
    animateScrollTo(target, _options).then((value) => {
      const newHash = target.id.replace('-target', '');
      // active link in header
      let _link = document.querySelector('.nav--link-header[data-hash="#' + newHash + '"]');
      if (_link) {
        this.headerLinks.forEach(navigationLink => {
          navigationLink.classList.add('decoration-theme-transparent');
          navigationLink.classList.remove('decoration-theme-current');
          navigationLink.classList.remove('hover:decoration-theme-current');
        });
        _link.classList.remove('decoration-theme-transparent');
        _link.classList.add('hover:decoration-theme-current');
        _link.classList.add('decoration-theme-current');
      }

      // update hash in window location
      if (target.id) {
        window.location.hash = newHash
      }
    });
  }

}
