export default class Navigation {

  constructor(selector) {
    this.header = document.querySelector('#header');
    this.trigger = document.querySelector(selector);
    this.nav = document.querySelector('.header--navigation');
    this.active = false;

    if (this.nav && this.trigger) {
      this.trigger.addEventListener('click', (event) => {
        event.stopPropagation();
        if (!this.active) {
          this.header.classList.add('navigation__active')
          this.active = true;
        }
        else {
          this.header.classList.remove('navigation__active')
          this.active = false;
        }
      });

      this.nav.addEventListener('click', (event) => {
        if (this.active && event.target != this.trigger) {
          setTimeout(() => {
            this.header.classList.remove('navigation__active')
            this.active = false;
          }, 100)
        }
      });
    }
  }

}
