import barba from '@barba/core';
import { gsap, ScrollTrigger } from "gsap/all";

import Navigation from "./navigation";
import Reveal from "./reveal";
import Slider from "./slider";
import Jumpmenu from './jumpmenu';

export default class BarbaJs {

  constructor() {
    // const html = document.querySelector('html');
    const body = document.querySelector('body');
    const langSwitcher = document.querySelector('.language-switcher');
    const navigationLinks = document.querySelectorAll('.navigation--header-link');
    const navigationLinksAnchor = document.querySelectorAll('.nav--link-header');


    barba.init({
      timeout: 5000,

      // debug: true,
      // cacheIgnore: true,
      // prefetchIgnore: true,

      // requestError: (trigger, action, url, response) => {
      //   console.log({
      //     trigger: trigger,
      //     action: action,
      //     url: url,
      //     response: response,
      //   })
      // },

      prevent: ({ el }) => el.classList && (el.classList.contains('prevent-barba')),

      transitions: [
        {
          name: 'default-transition',

          once(data) {
            setTimeout(() => {
              new Navigation('#navigation--trigger');
              new Reveal('.js--reveal');
              new Slider('.js--slider');
              new Jumpmenu();
            }, 1);

            return gsap.to(body, {
              opacity: 1,
              duration: 0.5,
              delay: 0.25,
            });
          },

          beforeLeave(data) {
            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 0.5,
              delay: 0.25,
            });

          },

          afterLeave(data) {
            // update body classes
            body.classList.toggle('is-home', data.next.namespace === 'home');
            body.classList.toggle('not-home', data.next.namespace !== 'home');
            data.current.container.remove();
            ScrollTrigger.killAll();
          },

          beforeEnter(data) {
            if (langSwitcher != null) {
              langSwitcher.href = data.next.container.dataset.translationUrl;
            }

            navigationLinksAnchor.forEach(navigationLink => {
              if (navigationLink != data.trigger) {
                navigationLink.classList.remove('decoration-theme-current');
                navigationLink.classList.remove('hover:decoration-theme-current');
                navigationLink.classList.add('decoration-theme-transparent');
              }
              else {
                navigationLink.classList.add('decoration-theme-current');
                navigationLink.classList.add('hover:decoration-theme-current');
              }
            });

            if (data.trigger.hash) {
              navigationLinks.forEach(navigationLink => {
                navigationLink.classList.remove('decoration-theme-current');
                navigationLink.classList.remove('hover:decoration-theme-current');
                navigationLink.classList.add('decoration-theme-transparent');
              });
            }
            else {
              navigationLinks.forEach(navigationLink => {
                navigationLink.classList.toggle('decoration-theme-current', navigationLink.dataset.slug === data.next.namespace || navigationLink.dataset.slug === data.next.container.dataset.slug);
                navigationLink.classList.toggle('decoration-theme-transparent', navigationLink.dataset.slug !== data.next.namespace && navigationLink.dataset.slug !== data.next.container.dataset.slug);
                navigationLink.classList.toggle('hover:decoration-theme-current', navigationLink.dataset.slug !== data.next.namespace && navigationLink.dataset.slug !== data.next.container.dataset.slug);
              });
            }
            data.next.container.style.opacity = 0;
          },


          enter(data) {
            // add hash of trigger to the location again
            // do not know why it is removed …
            if (data.trigger.hash) {
              window.location.hash = data.trigger.hash;
            }
            // reinit JS
            setTimeout(() => {
              new Navigation('#navigation--trigger');
              new Reveal('.js--reveal');
              new Slider('.js--slider');
              new Jumpmenu();
            }, 1);

          },

          afterEnter(data) {
            window.scrollTo(0, 0);

            return gsap.to(data.next.container, {
              opacity: 1,
              duration: 0.5,
              delay: 0.25,
            });

          }

        },
      ],

    });
  }

}
